import React, { useState, useEffect } from 'react';
import {
  ComponentWrapper,
  CardSlider,
  Card,
  Tabs,
  Toggle,
  Modal,
  Avatar,
  Button,
  BUTTON_TYPES
} from 'day8-react/build/new-components';
import PropTypes from 'prop-types';

const Mobile = props => {
  const { destinations } = props;
  const destTabs = [];
  Object.keys(destinations).forEach(key => {
    const destHolder = { text: destinations[key].title.rendered, content: destinations[key] };
    destTabs.push(destHolder);
  });
  const [activeHighlight, setActiveHighlight] = useState(destTabs[0].content.acf.highlights[0]);
  const [activeDestination, setActiveDestination] = useState(0);
  useEffect(() => setActiveHighlight(destTabs[activeDestination].content.acf.highlights[0]), [activeDestination]);
  return (
    <div className="d8-c-all-highlights max-width-sm margin-auto padding-md padding-0@md">
      <Tabs className="margin-top-lg relative" filterOptions={destTabs} centerOptions>
        {({ activeFilter }) => {
          setActiveDestination(activeFilter);
          return (
            <React.Fragment>
              {destTabs[activeFilter].content.acf.highlights && (
              <ComponentWrapper>
                <CardSlider>
                  { destTabs[activeFilter].content.acf.highlights.map(highlight => (
                    <div key={highlight.id} className={`d8-u-hover margin-right-md btn--underline ${activeHighlight.id === highlight.id && 'btn--underline active'}`} onClick={() => setActiveHighlight(highlight)}>
                      <Avatar
                        className="margin-auto"
                        image={{
                          url: highlight.featured_image_src,
                          alt: highlight.title.rendered
                        }}
                      />
                      <p className="margin-top-md d8-u-text-bold text-center text-xs">{highlight.title.rendered}</p>
                    </div>
                  ))}
                </CardSlider>
              </ComponentWrapper>
              )}
              {activeHighlight && (
              <div className="margin-top-md">
                {activeHighlight.acf.video ? (
                  <Toggle key={JSON.stringify(activeHighlight)}>
                    {({ toggle, on }) => (
                      <React.Fragment>
                        <Card
                          template="ImageCard"
                          size="aspect-16-9"
                          imageCardTemplate="Horizontal"
                          video={activeHighlight.acf.video}
                          bgImage={{
                            url: activeHighlight.featured_image_src,
                            alt: activeHighlight.title.rendered
                          }}
                          overlay
                        />
                        <div className="absolute" style={{ bottom: 20, right: 20 }}>
                          <Button
                            buttonType={BUTTON_TYPES.WHITE}
                            icon="info-i-fill"
                            text="Learn more"
                            onClick={toggle}
                          />
                        </div>
                        <Modal style={{ width: 'var(--max-width-xxs)', minWidth: 'unset' }} template="MediaModal" on={on} toggle={toggle} title={activeHighlight.title.rendered.replace(/<[^>]*>/g, '')} text={activeHighlight && activeHighlight.content && activeHighlight.content.rendered && activeHighlight.content.rendered.replace(/<[^>]*>/g, '')} image={{ url: activeHighlight.featured_image_src, alt: activeHighlight.title.rendered.replace(/<[^>]*>/g, '') }} video={activeHighlight.acf && activeHighlight.acf.video} />
                      </React.Fragment>
                    )}
                  </Toggle>
                ) : (
                  <Toggle key={JSON.stringify(activeHighlight)}>
                    {({ toggle, on }) => (
                      <React.Fragment>
                        <Card
                          template="ImageCard"
                          size="aspect-16-9"
                          imageCardTemplate="Basic"
                          bgImage={{
                            url: activeHighlight.featured_image_src,
                            alt: activeHighlight.title.rendered
                          }}
                          overlay
                        />
                        <div className="absolute" style={{ bottom: 20, right: 20 }}>
                          <Button
                            buttonType={BUTTON_TYPES.WHITE}
                            icon="info-i-fill"
                            text="Learn more"
                            onClick={toggle}
                            iconFlip
                          />
                        </div>
                        <Modal style={{ width: 'var(--max-width-xxs)', minWidth: 'unset' }} template="MediaModal" on={on} toggle={toggle} title={activeHighlight.title.rendered.replace(/<[^>]*>/g, '')} text={activeHighlight && activeHighlight.content && activeHighlight.content.rendered && activeHighlight.content.rendered.replace(/<[^>]*>/g, '')} image={{ url: activeHighlight.featured_image_src, alt: activeHighlight.title.rendered.replace(/<[^>]*>/g, '') }} video={activeHighlight.acf && activeHighlight.acf.video} />
                      </React.Fragment>
                    )}
                  </Toggle>
                )}
              </div>
              )}
            </React.Fragment>
          );
        }
          }
      </Tabs>
    </div>
  );
};

Mobile.propTypes = {
  destinations: PropTypes.array
};

export default Mobile;
