import React from 'react';
import {
  ComponentWrapper,
  ComponentVerticalSpacing,
  Card,
  Button,
  BUTTON_TYPES,
  Pill
} from 'day8-react/build/new-components';
import PropTypes from 'prop-types';

const BookingDates = ({ bookingdates }) => (
  <div
    className="flex justify-center d8-u-bg-img"
    style={{ backgroundImage: `url(${bookingdates.bgimage})` }}
  >
    <ComponentVerticalSpacing>
      <ComponentWrapper>
        <div className="max-width-xxs margin-auto margin-bottom-xl">
          <h2 className="text-center margin-bottom-md">{bookingdates.title}</h2>
          <p className="margin-bottom-xl">{bookingdates.text}</p>
        </div>
        <div className="max-width-lg margin-auto">
          <div className="d8-l-3-col-grid">
            {bookingdates.cards
              && bookingdates.cards.map(card => (
                <Card key={card.card.title}>
                  <div className="flex flex-column justify-between height-100%">
                    <div>
                      {card.card.pill.pill && (
                        <div className="flex justify-end margin-bottom-md">
                          <Pill
                            pill={card.card.pill.pill}
                            iconcolor={card.card.pill.iconcolor}
                          />{' '}
                        </div>
                      )}
                      <div className="text-component">
                        <p className="d8-u-color-contrast-high text-sm d8-u-font-weight-500">
                          {card.card.title}
                        </p>
                        <h3 className="d8-u-color-primary">
                          {card.card.dates}
                        </h3>
                        <p>{card.card.text}</p>
                      </div>
                    </div>
                    {card.card.button.text && (
                      <div>
                        <Button
                          className="margin-top-md"
                          text={card.card.button.text}
                          link={card.card.button.link}
                          buttonType={
                            BUTTON_TYPES[card.card.button.button_type]
                          }
                          icon={card.card.button.icon}
                          iconFlip={card.card.button.icon_flip}
                          external={card.card.button.external}
                          fullWidth={card.card.button.fullWidth}
                        />
                      </div>
                    )}
                  </div>
                </Card>
              ))}
          </div>
        </div>
      </ComponentWrapper>
    </ComponentVerticalSpacing>
  </div>
);

BookingDates.propTypes = {
  bookingdates: PropTypes.object
};

export default BookingDates;
