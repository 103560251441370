import React from 'react';
import Media from 'react-media';
import {
  ComponentWrapper,
  ComponentVerticalSpacing,
  BREAKPOINTS
} from 'day8-react/build/new-components';
import PropTypes from 'prop-types';

import Desktop from './Templates/Desktop';
import Mobile from './Templates/Mobile';

export default class AllHighlights extends React.Component {
  state = {
    key: 0
  };

  componentDidMount() {
    this.keyHack();
  }


  keyHack = () => this.setState({ key: 1 });

  render() {
    const { destinations } = this.props;
    const { key } = this.state;
    return (
      <ComponentVerticalSpacing>
        <ComponentWrapper className="max-width-xxxs margin-auto">
          <h2 className="text-center margin-bottom-md">
            Highlights
          </h2>
        </ComponentWrapper>
        <Media key={key} query={{ minWidth: BREAKPOINTS.SM }}>
          {matches => matches ? (
            <Desktop destinations={destinations} />
          ) : (
            <Mobile destinations={destinations} />
          )}
        </Media>
      </ComponentVerticalSpacing>
    );
  }
}

AllHighlights.propTypes = {
  destinations: PropTypes.array
};
