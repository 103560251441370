import React from 'react';
import Media from 'react-media';
import {
  Card,
  Link,
  BREAKPOINTS,
  Svg,
  CardSlider,
  Button,
  BUTTON_TYPES,
  ComponentWrapper,
  ComponentVerticalSpacing
} from 'day8-react/build/new-components';
import PropTypes from 'prop-types';

export default class Intro extends React.Component {
  state = {
    key: 0
  };

  componentDidMount() {
    this.keyHack();
  }

  keyHack = () => this.setState({ key: 1 });

  render() {
    const {
      destinations,
      bgImage,
      text,
      title
    } = this.props;
    const { key } = this.state;
    return (
      <div className="position-relative">
        <div className="intro-bg d8-u-bg-img width-100% position-absolute" style={{ backgroundImage: `url(${bgImage})`, height: '60%' }} />
        <ComponentVerticalSpacing noTop className="padding-top-xl">
          <ComponentWrapper>
            <div
              className="margin-bottom-xl text-center position-relative text-component"
            >
              <h2 className="max-width-xxxxs margin-auto">
                {title}
              </h2>
              <p>
                {text}
              </p>
            </div>
            <Media key={key} query={{ minWidth: BREAKPOINTS.SM }}>
              {matches => matches ? (
                <div className="padding-sm">
                  <div className="max-width-md margin-auto">
                    <div className="grid grid-gap-sm d8-u-relative">
                      <div className="d8-c-destination-decorator">
                        <Svg name="our-mountians" className="icon--xs" />
                      </div>
                      {destinations && destinations.map(destination => (
                        <div key={destination.id} className="col-4@md padding-sm">
                          <Link href={`/${destination.slug}`}>
                            <Card
                              template="ImageCard"
                              imageCardTemplate="Destination"
                              size="xl"
                              className="d8-u-hover-shadow-xl overflow-hidden destination-card-width"
                              soldOut={!destination.fromPrice && !destination.acf.info.price}
                              bgImage={{
                                url: destination.acf.image,
                                alt: 'The Ski Week'
                              }}
                              image
                              price={destination.fromPrice || destination.acf.info.price || null}
                              currency={destination.currency || (parseInt(destination.acf.destination_id, 10) === 2 ? 'USD' : 'EUR')}
                              textMain={destination.acf.hero.subtitle}
                              whiteFont
                              overlay
                            />
                          </Link>
                          <p className="d8-u-text-bold text-center margin-top-lg d8-u-color-black">{destination.acf.subtitle}</p>
                          <p className="d8-u-text-bold text-sm text-center margin-top-sm d8-u-color-primary">
                            {destination.acf.info.dates.map((d, i, arr) => (
                              <React.Fragment key={i}>
                                { arr.length - 1 === i ? (
                                  <span className={d.soldout && 'd8-u-text-line-through d8-u-color-contrast-medium'}>
                                    {d.date}
                                  </span>
                                ) : (
                                  <React.Fragment>
                                    <span className={d.soldout && 'd8-u-text-line-through d8-u-color-contrast-medium'}>
                                      {d.date}
                                    </span>
                                    {' '}/{' '}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            ))}
                          </p>
                          {destination.acf.tags && (
                          <div className="flex flex-center margin-top-sm">
                            {destination.acf.tags.map((tag, i) => (
                              <span key={i} className="pill pill--sm pill--standard margin-x-xs">{tag.text}</span>
                            ))}
                          </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <CardSlider>
                    {destinations && destinations.map(destination => (
                      <div key={destination.id} className="col-4@md padding-sm">
                        <Link href={`/${destination.slug}`}>
                          <Card
                            template="ImageCard"
                            imageCardTemplate="Destination"
                            size="xl"
                            bgImage={{
                              url: destination.acf.image,
                              alt: 'The Ski Week'
                            }}
                            image
                            price={destination.acf.info.price}
                            textMain={destination.acf.hero.subtitle}
                            whiteFont
                            overlay
                          />
                        </Link>
                        <p className="d8-u-text-bold text-center margin-top-lg d8-u-color-black">{destination.acf.subtitle}</p>
                        <p className="d8-u-text-bold text-sm text-center margin-top-sm d8-u-color-primary">
                          {destination.acf.info.dates.map((d, i, arr) => (
                            <React.Fragment key={i}>
                              { arr.length - 1 === i ? (
                                <span>{d.date}</span>
                              ) : (
                                <span>{d.date} / </span>
                              )}
                            </React.Fragment>
                          ))}
                        </p>
                        {destination.acf.tags && (
                        <div className="flex flex-center margin-top-sm">
                          {destination.acf.tags.map((tag, i) => (
                            <span key={i} className="pill pill--sm pill--standard margin-x-xs">{tag.text}</span>
                          ))}
                        </div>
                        )}
                      </div>
                    ))}
                  </CardSlider>
                  <div className="padding-sm text-center">
                    <Link href="/destination-guide">
                      <Button buttonType={BUTTON_TYPES.PRIMARY} text="Explore destinations" />
                    </Link>
                  </div>
                </div>
              )}
            </Media>
          </ComponentWrapper>
        </ComponentVerticalSpacing>
      </div>
    );
  }
}

Intro.propTypes = {
  destinations: PropTypes.array,
  bgImage: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string
};
